import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import ChangeLang from './ChangeLang';
import DesktopSwitcher from '../../DesktopSwitcher';
import { selectDetailsModalState } from '../../../store/selectors';
import { toggleDetailsModal } from '../../../actions';

const MenuList = ({ onPhoneClickHandler }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { isVisible: isVisibleDetailsModal } = useSelector(
    selectDetailsModalState
  );
  const menuListItems = [
    {
      name: 'rent',
      path: '/[...search]?url=/alqi-satqi',
      as: '/alqi-satqi',
      text: t('main_menu.sale')
    },
    {
      name: 'lease',
      path: '/[...search]?url=/kiraye',
      as: '/kiraye',
      text: t('main_menu.lease')
    },
    {
      name: 'agencies',
      path: '/agencies',
      as: '/agentlikler',
      text: t('main_menu.agencies')
    },
    {
      name: 'residences',
      path: '/residences',
      as: '/yasayis-kompleksleri',
      text: t('main_menu.residences')
    }
  ];

  const getItemClasses = additionalClassName =>
    cn('m-nav__link', additionalClassName, {
      'm-nav__details-link': isVisibleDetailsModal
    });

  const onItemClickHandler = () => {
    dispatch(toggleDetailsModal(false));
  };

  const MenuItem = ({
    path,
    text,
    as,
    additionalClassName,
    onClickHandler
  }) => {
    if (onClickHandler) {
      return (
        <li>
          <div
            role={'button'}
            tabIndex={-1}
            onClick={onClickHandler}
            onKeyPress={onClickHandler}
            className={getItemClasses(additionalClassName)}
            data-cy="menu-link"
          >
            {text}
          </div>
        </li>
      );
    }

    return (
      <li>
        <Link
          href={path}
          as={as}
          className={getItemClasses(additionalClassName)}
          onClick={onItemClickHandler}
          onKeyPress={onItemClickHandler}
          data-cy="menu-link"
        >
          {text}
        </Link>
      </li>
    );
  };

  const ChangeLanguageItem = ({ additionalClassName }) => {
    return (
      <li>
        <ChangeLang
          i18n={i18n}
          t={t}
          className={getItemClasses(additionalClassName)}
        />
      </li>
    );
  };

  const DesktopSwitcherItem = ({ additionalClassName }) => {
    return (
      <li>
        <DesktopSwitcher
          className={getItemClasses(additionalClassName)}
          cypress="menu-link"
        />
      </li>
    );
  };

  ChangeLanguageItem.propTypes = {
    additionalClassName: PropTypes.string
  };

  DesktopSwitcherItem.propTypes = {
    additionalClassName: PropTypes.string
  };

  MenuItem.propTypes = {
    path: PropTypes.string,
    as: PropTypes.string,
    text: PropTypes.string,
    additionalClassName: PropTypes.string,
    onClickHandler: PropTypes.func
  };

  return (
    <div
      className={cn('m-nav__list', {
        'm-nav__details': isVisibleDetailsModal
      })}
    >
      <ul>
        {isVisibleDetailsModal && (
          <ChangeLanguageItem additionalClassName={'m-nav__details-link-top'} />
        )}
        {menuListItems.map(({ name, path, text, as }) => (
          <MenuItem key={name} path={path} text={text} as={as} />
        ))}
        {isVisibleDetailsModal && (
          <>
            <MenuItem
              key={'connect'}
              text={t('common.connect_us')}
              onClickHandler={onPhoneClickHandler}
              additionalClassName={'m-nav__details-link-bottom'}
            />
            <DesktopSwitcherItem
              additionalClassName={'m-nav__details-link-bottom-transparent'}
            />
          </>
        )}
      </ul>
    </div>
  );
};

MenuList.propTypes = {
  onPhoneClickHandler: PropTypes.func
};

export default MenuList;
