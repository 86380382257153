import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import Logo from '../../public/static/images/brown-logo.svg';
import Plus from '../../public/static/images/green-plus.svg';
import {
  setChosenLocationNames,
  setCurrentState,
  switchTab
} from '../../actions';

const WhiteHeader = ({ headerTitle = '', isFixedHeader }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const headerClassNames = classNames('white-header-container', {
    'white-header-container--fixed': isFixedHeader
  });

  const clickHandle = () => {
    dispatch(switchTab(0));
    dispatch(setChosenLocationNames([]));
    dispatch(setCurrentState(null));

    return router.push('/');
  };

  return (
    <div className={headerClassNames}>
      {headerTitle ? (
        <span className="white-header-title">{headerTitle}</span>
      ) : (
        <div className="white-header-btns">
          <a
            className="white-header-logo"
            data-cy="white-header-logo"
            onClick={clickHandle}
            onKeyPress={clickHandle}
            role="button"
            tabIndex="-1"
          >
            <Icon IconComponent={Logo} width={85} height={19} />
          </a>
          <a className="white-header-new-btn" href="/items/new">
            <Icon IconComponent={Plus} width={30} height={30} indent={false} />
          </a>
        </div>
      )}
    </div>
  );
};

WhiteHeader.propTypes = {
  headerTitle: PropTypes.string,
  isFixedHeader: PropTypes.bool
};

export default WhiteHeader;
