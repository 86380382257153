import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuList from '../../Menu/MenuGroups/Menu';
import HeaderPlaceholder from '../../Placeholders/HeaderPlaceholder';
import EmailIcon from '../../../public/static/images/social/email.svg';
import FacebookIcon from '../../../public/static/images/social/facebook.svg';
import InstagramIcon from '../../../public/static/images/social/instagram.svg';
import PhoneIcon from '../../../public/static/images/phone_call_brown-icon.svg';
import Icon from '../../Icon';
import BinaModal from '../../ReactModal';
import CONFIG from '../../../config';
import useToggle from '../../../hooks/useToggle';
import { selectDetailsModalState } from '../../../store/selectors';
import WhiteHeader from '../../WhiteHeader';

const ContactInfoModal = dynamic(() => import('../../ContactInfoModal'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const DetailsPage = () => {
  const { t } = useTranslation();
  const [isOpenModal, toggleModal] = useToggle(false);
  const [showPhone, setShowPhone] = useState(false);
  const { isVisible: isVisibleDetailsModal } = useSelector(
    selectDetailsModalState
  );
  const {
    contacts: { tel, tel_url: telUrl, email, instagramLink, facebookLink }
  } = CONFIG;

  const contactsListItems = [
    {
      icon: EmailIcon,
      path: `mailto:${email}`,
      title: t('common.write'),
      name: 'email'
    },
    {
      icon: FacebookIcon,
      path: facebookLink,
      title: 'Facebook',
      name: 'facebook'
    },
    {
      icon: InstagramIcon,
      path: instagramLink,
      title: 'Instagram',
      name: 'instagram'
    }
  ];

  const ContentItem = ({ icon, text }) => (
    <>
      <div className={'contacts-info-item-block__icon'}>{icon}</div>
      <div className={'contacts-info-item-block__title'}>{text}</div>
    </>
  );

  const PhoneItem = ({ icon, text, onClickHandler }) => (
    <div
      role={'button'}
      tabIndex={-1}
      className={'contacts-info-item-block'}
      onClick={onClickHandler}
      onKeyPress={onClickHandler}
    >
      <ContentItem icon={icon} text={text} />
    </div>
  );

  const ContactItem = ({ icon, text, index, path }) => (
    <a
      href={path}
      role={'button'}
      tabIndex={index}
      className={'contacts-info-item-block'}
    >
      <ContentItem icon={icon} text={text} />
    </a>
  );

  const contactsList = contactsListItems.map(
    ({ icon, title, name, path }, i) => (
      <ContactItem
        icon={<Icon width={20} height={18} IconComponent={icon} />}
        text={title}
        key={name}
        path={path}
        index={i}
      />
    )
  );

  ContentItem.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string
  };

  PhoneItem.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string,
    onClickHandler: PropTypes.func
  };

  ContactItem.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string,
    index: PropTypes.number,
    path: PropTypes.string
  };

  const onItemPhoneClickHandler = () => {
    setShowPhone(prev => !prev);
  };

  const handleToggleModal = () => {
    toggleModal();
    setShowPhone(false);
  };

  return (
    <BinaModal
      modalId={'details-modal'}
      isOpen={isVisibleDetailsModal}
      overlayClassName="modal__overlay--deteils-modal"
      contentClassName="modal__content--deteils-modal"
    >
      <div className="modal__content--deteils-modal-container">
        <WhiteHeader headerTitle={t('pages.tab_bar.buttons.more')} />
        <MenuList onPhoneClickHandler={toggleModal} />
        <ContactInfoModal
          isOpen={isOpenModal}
          hide={handleToggleModal}
          title={showPhone ? t('common.call') : t('common.connect_us')}
        >
          {showPhone ? (
            <>
              <ContactItem
                icon={<Icon width={20} height={18} IconComponent={PhoneIcon} />}
                text={tel}
                key={'phonenumber'}
                path={telUrl}
                index={0}
              />
            </>
          ) : (
            <>
              <PhoneItem
                onClickHandler={onItemPhoneClickHandler}
                icon={
                  <Icon
                    width={20}
                    height={18}
                    color="brown"
                    IconComponent={PhoneIcon}
                  />
                }
                text={t('common.call')}
              />
              {contactsList}
            </>
          )}
        </ContactInfoModal>
      </div>
    </BinaModal>
  );
};

export default DetailsPage;
